.colortext{
    color: rgb(26, 136, 155);
}


* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage {
  position: relative;

}

/* Make the image to responsive */
.image1 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(11, 145, 182);
  background: rgba(16, 171, 185, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage:hover .overlay {
  opacity: 1;
}



.button32{
  background-color: #054562; /* Green */
  border: none;
  color: white;
  padding: 8px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 9px;
}

.button33{
  box-shadow: 0 8px 16px 0 rgba(11, 81, 109, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.buttuncallc{
  background-color: #054562; /* Green */
  border: none;
  color: white;
  padding: 8px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 9px;
}

.backgcolor{
  background-color:#054562;
}

.closebutton{
  background-color: rgb(19, 95, 112); /* Green */
  border: none;
  color: white;
  padding: 7px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}