.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


@import url("https://cdn.syncfusion.com/ej2/material.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #272729;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.App{
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
} */





.newc{
  display: flex ;
  margin-left: 40px;
 
}


.column {
  flex: 10%;
  margin-left: 30px;
  }
.column{
  display: flex;
  margin-left: 15px;
}
.articlenews img{
  display: flex;
 height:350px;
 width: 350px;
}


/* .row{
  display: flex;
  margin-left: 10px;
} */
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.nia{
  display: flex;
  gap:25px;
  width:500px;
}




.foewmp{
  display: flex;
  margin-left: 300px;
}



.sings{
  display: flex;
  margin-left: 535px;
  width: 450px;
}

.button-right{
  display: flex;
  justify-content: flex-end;
}

#recaptcha-container {
  margin: 20px;
}

 
 


a {
  text-decoration: none !important;
}

.textcolor{
  color: #108cb1;
}

.diagnostics{
  background-color: #e0f1f6;
  padding: 2px;
}

.buttoncall{
  background-color: rgb(12, 142, 171); /* Green */
  border: none;
  color: white;
  padding: 6px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 7px;
}

.buttonwhatsapp{
  background-color: rgb(12, 142, 171); /* Green */
  border: none;
  color: white;
  padding: 6px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 7px;
}

.navbarcall{
  background-color: rgb(10, 68, 82); /* Green */
  border: none;
  color: rgb(255, 255, 255);
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 7px;
}

.buttoncalldoctor{
  background-color: rgb(12, 142, 171); /* Green */
  border: none;
  color: white;
  padding: 6px 21px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 7px;
}

.buttonwhatsappdoctor{
  background-color: rgb(12, 142, 171); /* Green */
  border: none;
  color: white;
  padding: 6px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 7px;
}

.diagnosticskrsihnagar{
  color: rgb(26, 136, 155);
}

.homesample{
  background-color: rgb(12, 142, 171); /* Green */
  border: none;
  color: white;
  padding: 6px 27px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 7px;
}


.textjustify{
  text-align: justify;
  text-justify: inter-word;
}



.btn-grad20 {
  background-image: linear-gradient(to right, #094f49 0%, #0d8c9f  51%, #2b5876  100%);
  margin: 0px;
  padding: 9px 20px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 4px;
  display: block;
}

.btn-grad20:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.colort h5{
  color: #056c7b;
}

.borderr img{
border-radius: 11px;
}


.blink { 
  animation: blink-animation 1s infinite; 
} 
  
@keyframes blink-animation { 
  0% { 
    opacity: 1; 
  } 
  50% { 
    opacity: 0; 
  } 
  100% { 
    opacity: 1; 
  } 
}


.cardnew {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 2px 1px 0 rgba(0,0,0,0.2);
  transition: 0.1s;
}

/* On mouse-over, add a deeper shadow */
.cardnew:hover {
  box-shadow: 0 4px 32px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.containerso {
  padding: 2px 16px;
}


.button {
  background-color: #730d54; /* Green */
  border: none;
  color: white;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.button1 {
  background-color: white; 
  color: black; 
  border: 2px solid #a5b5ba;
}

.button1:hover {
  background-color: #0e455f;
  color: white;
}


.buttoncallen{
  background-color: rgb(12, 142, 171); /* Green */
  border: none;
  color: white;
  padding: 7px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.uploadbutton{
  background-color: rgb(19, 95, 112); /* Green */
  border: none;
  color: white;
  padding: 7px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.backcmew{
  background-color: #d9e9ec;
}


.button {
  background-color: #960544; /* Green */
  border: none;
  color: white;
  padding: 4px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 13px;
}

.button1 {
  background-color: white; 
  color: black; 
  border: 2px solid #9f044c;
}



.colorbackground{
  background-color: #098fa3;
}

.offbutton{
  background-color: #107e91; /* Green */
  border: none;
  color: white;
  padding: 4px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 03px;
}
