.questcolor{
 background-color: rgb(15, 96, 113);   
}

.button21 {
    transition-duration: 0.4s;
  }
  
  .button21:hover {
    background-color: #5ccbcf; /* Green */
    color: rgb(17, 15, 15);
    padding: 6px;
  }